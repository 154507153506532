import { extendTheme } from "@chakra-ui/react";

export const menuItemStyle = {
  borderRadius: "4px",
  backgroundColor: "#E6F0FF",
};

export const menuListStyle: React.CSSProperties = {
  minWidth: "8rem",
  padding: "5px",
  fontSize: "13px",
  color: "black",
  maxHeight: "20vh",
  overflowY: "auto",
};

const theme = extendTheme({
  styles: {
    global: {
      body: {
        fontFamily: "'Inter', sans-serif",
        color: "#001E4D",
        fontSize: "14px",
        letterSpacing: "0.2px",
      },
    },
  },
  config: {
    initialColorMode: "light",
    useSystemColorMode: false,
  },
  fontSizes: {
    xs: "11px",
    sm: "12px",
    reg: "14px",
    md: "16px",
    lg: "17px",
    icon: "20px",
    xl: "24px",
  },
  components: {
    FormLabel: {
      baseStyle: {
        fontSize: "14px",
      },
    },
    Select: {
      variants: {
        outline: {
          field: {
            bg: "white",
          },
        },
      },
      defaultProps: {
        size: "sm",
      },
    },
    Switch: {
      defaultProps: {
        size: "md",
      },
    },
    Input: {
      variants: {
        outline: {
          field: {
            bg: "white",
          },
        },
      },
      defaultProps: {
        size: "sm",
      },
    },
    Button: {
      variants: {
        outline: {
          bg: "white",
        },
      },
      defaultProps: {
        variant: "outline",
        size: "md",
      },
    },
  },
});

export default theme;
