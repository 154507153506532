import { createContext } from "react-immersive";
import { CreateWorkflowProcessorRequestSubscriptionsInner } from "../client";

export const keyIsEdited = "isEdited";

const initialState = {
  integrations: {} as Record<string, any>,
  tracingID: "",
  tracingSelectedEdgeID: "",
  tracingLogID: "",
  isEdited: "",
  sourceElement: {
    id: "",
    output: "",
    position: "",
    subscriptions: [{ instanceID: "", bucket: "" }],
  },
};

export default createContext(initialState, (modify) => ({
  setIntegrations(integrations: typeof initialState["integrations"]) {
    modify((draft) => {
      draft.integrations = integrations;
    });
  },
  trace(tracingID: string) {
    modify((draft) => {
      draft.tracingID = tracingID;
    });
  },

  traceSelectedEdge(tracingSelectedEdgeID: string) {
    modify((draft) => {
      draft.tracingSelectedEdgeID = tracingSelectedEdgeID;
    });
  },

  editStatusWorkflow(isEdited: string) {
    modify((draft) => {
      draft.isEdited = isEdited;
    });
  },

  traceLog(tracingLogID: string) {
    modify((draft) => {
      draft.tracingLogID = tracingLogID;
    });
  },

  connect({
    id,
    output,
    position,
    subscriptions,
  }: {
    id: string;
    output: string;
    position: string;
    subscriptions: Array<CreateWorkflowProcessorRequestSubscriptionsInner>;
  }) {
    modify((draft) => {
      draft.sourceElement.id = id;
      draft.sourceElement.output = output;
      draft.sourceElement.position = position;
      draft.sourceElement.subscriptions = subscriptions;
    });
  },
}));
